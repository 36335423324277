import { Link } from 'react-router-dom';

const Announcements = () => {
    return (
        <Link to='/dashboard' className='announcements-wrapper'>
            <div className='fade-off' />
            <p className='a-icon'>🎲</p>
            <p>Follow us on Instagram @laboardclub</p>
            {/* <p className='a-icon'>⚔️</p>
            <p>Y2K Party and Rivaly Week Tailgate this week!</p> */}
        </Link>
    );
};

export default Announcements;
